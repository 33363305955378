#assetTelemetryDetail {
  .card-body {
    #Loading {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }
}

