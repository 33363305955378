@import "../../variables";

.layout {
  height: 100%;
  position: relative;

  > main {
    flex-grow: 1;
  }
}
