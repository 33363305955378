@import "../../variables";

%reset-height {
  height: auto;
  line-height: inherit;
}

%highlight {
  background-color: $highlight-bg-color;
  color: $highlight-color;
}

.pro-sidebar {
  > .pro-sidebar-inner {
    .pro-sidebar-footer {
      @extend %reset-height;

      .pro-menu {
        padding: 0;
      }
    }

    .pro-sidebar-header {
      @extend %reset-height;
    }
  }

  .pro-menu {
    .pro-menu-item {
      &.hidden {
        display: none;
      }

      &.active {
        @extend %highlight;

        .pro-inner-item {
          border-left-color: $sidebar-border-highlight-color;
        }

        a {
          color: $highlight-color;
        }
      }

      .pro-inner-item {
        border: $sidebar-border-width solid transparent;
        padding: 0px (35px - $sidebar-border-width) 0px
          (20px - $sidebar-border-width);
        &,
        a {
          &:hover {
            @extend %highlight;
          }
        }
      }

    }
  }
}

.Avatar {
  padding: 0.625rem 0;
}

