.arrow-icon {
  > .btn {
    cursor: auto;
    > svg {
      cursor: pointer;
    }
    > svg:hover {
      transform: scale(1.3);
    }
  }
}
