.row {
  margin: 0 -15px 0 -15px;

  .alert {
    display: flex;
    align-items: center;
    height: 3rem;
  }

  > * {
    padding: 0 15px 0 15px;
  }

  .card-header {
    padding: 0.4rem 1.25rem;
  }

  .card-body {
    padding: 1.25rem;

    .useCaseImage {
      height: 100px;
      width: 150px;
    }
  }
}
