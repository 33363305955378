@import "../../variables.scss";

.information-card:hover {
  cursor: pointer;
  background-color: rgba(169, 169, 169, 0.3);
  border-radius: 0.3rem;
}

.icon-color {
  color: $primary;
}

.highlight-background {
  background-color: rgba(169, 169, 169, 0.3);
}

.go-back-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.rules-list {
  .information-card {
    > p {
      margin-left: 0.5rem;
    }
  }
}
