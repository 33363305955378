@import "../../variables";

.DataTable {
  thead > tr > th.sortable {
    &:hover {
      cursor: pointer;

      > .SortIndicator {
        > .icon {
          visibility: visible;
        }
      }
    }
  }
}
