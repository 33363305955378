@import "../../variables.scss";

.DataTable {
  &.card {
    overflow: hidden;
    > .card-body {
      padding: 0;
    }
  }

  .table {
    margin: 0;

    > thead {
      background-color: #e9ecef;
      border-style: none;
      tr,
      th {
        border-style: none;
        border-width: 0;
      }

      > tr:first-child {
        th {
          border-top: 0;
        }
      }
    }

    th,
    td {
      text-align: center;
      vertical-align: middle;
    }
  }
}
