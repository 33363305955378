@import "../../variables.scss";

.SelectableList {
  overflow: hidden;
  .card-body {
    overflow-y: auto;
    max-height: 10rem;
  }
  .list-group-item {
    &:first-child {
      border-radius: 0;
    }
    overflow: hidden;
    cursor: pointer;
    &:hover {
      text-decoration: $link-decoration;
    }
  }
  h4 {
    padding: $list-group-item-padding-y $list-group-item-padding-x;
  }
}
