#progress-point-last {
  .col-sm-1 {
    height: 1rem;
  }
}

#progress-point, #progress-point-last {
  .col-sm-1 {
    position: relative;
    .progress-line {
      width: 0.1rem;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
