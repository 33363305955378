// bootstrap default ovverides before the import
$primary: #08546b;
$secondary: #5698ad;
$body-color: #354051;
$card-header: #00546c;
$burger-menu: #d0d0d0;
$body-bg: rgb(236, 242, 246);

$card-border-radius: 0.8rem;
$card-border-color: rgba(0, 0, 0, 0.25);
$card-cap-color: $primary;
$card-spacer-y: 0.4rem;
$input-btn-focus-width: 0rem;
$input-btn-focus-box-shadow: none;

$headings-color: $primary;
$headings-line-height: 1;

$border-color: #efefef;

$min-contrast-ratio: 3;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$sidebar-bg-color: $primary;
$sidebar-color: $white;
$sidebar-width: 210px;
$highlight-color: darken($sidebar-color, 10%);
$icon-bg-color: rgba($sidebar-color, 0.2);

// our custom variables here
$highlight-bg-color: #262d38;
$sidebar-border-width: 5px;
$sidebar-border-highlight-color: lighten($sidebar-bg-color, 10%);
