@import "../../variables.scss";

#breadcrumb {
  background-color: $white;
  align-items: center;
  z-index: 999;

  > ol {
    background: transparent;
    display: flex;
    padding: 0.5rem 1rem;
    margin: 0;
    font-size: 1.25em;

    > li {
      height: 1.75em;

      a:hover {
        text-decoration-line: underline;
      }
    }

    > li.active {
      color: $body-color;
      font-weight: 650;
      text-decoration: underline;
      text-decoration-color: $primary;
      text-decoration-thickness: 0.1em;
    }

    > li:before {
      font-weight: 400;
    }
  }
}
