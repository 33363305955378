#map-container {
  > .location {
    &.fullscreenOn {
      .mapboxgl-map {
        height: 95vh !important;
      }
    }
    &:not(.fullscreenOn) {
      .mapboxgl-map {
        height: 70vh !important;
      }
    }
    > #map > .mapboxgl-map {
      border-radius: 0 0 0.8rem 0.8rem;
    }
  }
}
