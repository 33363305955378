@import "variables";
@import "utilities";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@import "~react-pro-sidebar/dist/scss/styles.scss";

*:focus {
  outline: none !important;
}

body {
  height: 100vh;

  a {
    text-decoration-line: none;
    :hover {
      text-decoration-line: underline;
    }
  }

  #page-wrap {
    padding-left: 15px;
    padding-right: 15px;
    .dropdown {
      &.show {
        > .btn-light.dropdown-toggle {
          background-color: #dae0e5;
        }
      }
      > .btn-light.dropdown-toggle:hover {
        color: $black;
        background-color: #dae0e5;
      }
    }

    .alert {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    #map-container {
      margin-top: 1rem;
    }

    #assetMasterData,
    #assetTelemetryDetail {
      margin-top: 1rem;
      .card-header {
        padding: 0.4rem 1.25rem;
      }
      .card-body {
        padding: 1.25rem;
      }
    }
  }

  .modal .modal-content > .modal-footer > .btn-secondary {
    background-color: #fff;
    color: #5698ad;
    &:hover {
      background-color: #5698ad;
      color: #fff;
    }
  }
}

#root {
  height: 100%;
}

// Date Range Picker Styling
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
