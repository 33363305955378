@import "../variables";

.LevelSelector {
  > .btn {
    color: #fff;
    min-width: 50px;
    min-height: 50px;
  }

  .dropdown-menu {
    min-width: auto;
    > .dropdown-item {
      padding-left: $spacer;
      padding-right: $spacer;
    }
  }
}
