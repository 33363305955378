@import "../_variables";

.alarm-btn {
  background-color: $white !important;
  border-color: $danger !important;
  color: $danger !important;
}

.alarm-btn:hover {
  background-color: #e74c3c !important;
  border-color: $white !important;
  color: $white !important;
}

.alarm-row {
  background-color: #e74c3c !important;
  color: $white !important;
}

.margin-5-20 {
  margin: 5px 20px;
}

.alarm-card-body {
  background-color: $danger;
  border-color: $black !important;
  color: $white !important;
  border-radius: 0.4rem;
}

.fullscreen {
  #alarmList {
    height: 100%;
  }
  #col-map{
    #map-container {
      margin-top: unset;
    }
  }

  > .fullscreenOn {
    min-height: 97vh;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    .col-auto {
      width: 15%;
      #alarmList {
        .card-header {
          padding: 0.2rem 1rem;
        }
      }
    }
  }
}
