@import "../../variables";

.LocatorEdit {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  &.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }

  > .col-10 {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    width: 100%;
    margin-top: 0;
    max-width: 83.33333%;
    flex: 0 0 83.33333%;
    .alert {
      position: absolute;
      top: $spacer;
      right: 30%;
      left: 30%;
    }
  }

  > .col-2 {
    padding-left: 0;
    padding-right: 0;
    max-width: 16.66667%;
    width: 100%;
    flex: 0 0 16.66667%;
    position: relative;
    .form-group {
      margin-bottom: 1rem;
      > label {
        margin-bottom: 0.5rem;
      }
    }
  }

  .LevelSelector {
    position: absolute;
    bottom: $spacer * 2;
    right: $spacer;
  }

  .button-section {
    .disabled {
      pointer-events: auto;
    }
  }
}
