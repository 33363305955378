@import "../../variables";

.SortIndicator {
  > .icon {
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }
}
