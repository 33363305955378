@import "react-multi-email/style.css";

.react-multi-email{
  background: #ecf2f6;
  &.focused{
    background: #ecf2f6;
  }
  >input{
    margin-left: .5em;
    background-color: transparent;
  }
}
