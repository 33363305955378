.TypeaheadField {
  > .invalid-feedback {
    display: inherit;
  }
}

div.rbt-token {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  color: var(--bs-body-color);
  cursor: default;
  font-size: 85%;
  padding: 5px 21px 5px 7px;
}

button.close.rbt-close.rbt-token-remove-button {
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  cursor: default;
  font-weight: 600;
  padding: 5px;
}

button.close.rbt-close.rbt-token-remove-button:hover {
  background-color: #FFBDAD;
  color: #DE350B;
}
