@import "../../variables.scss";

.ListView {
  .card {
    overflow: hidden;
    > .card-body {
      padding: 0;
    }
  }

  .table {
    margin: 0;

    > thead {
      > tr:first-child {
        th {
          border-top: 0;
        }
      }
    }

    th,
    td {
      text-align: center;
      vertical-align: middle;
    }
  }
}
