.Legend {
  h4 {
    margin: 0 0 10px;
  }

  div span {
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    margin-right: 1rem;
    width: 12px;
  }
}
