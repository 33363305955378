@import "../../variables.scss";
#geocordsModalBody {
  display: flex;
  flex-direction: column;
  &.small {
    height: 7.5vh;

    #location-select {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.big {
    height: 90vh;

    #location-select {
      margin-bottom: $spacer;
    }
  }

  .LevelSelector {
    position: absolute;
    bottom: $spacer * 2;
    right: $spacer;
  }
}

.modal-footer {

  .col-md-2{
    .btn {
      min-width: 100%;
    }
  }
}
