@import "../variables";

.ActionButtons {
  %spacing {
    margin: map-get($spacers, 1);
  }

  > .dropdown {
    display: inline-flex;
    vertical-align: middle;

    > button {
      color: white;
    }
  }

  > .btn-secondary {
    color: white;
  }

  > .btn {
    & + .btn,
    & + .dropdown {
      @extend %spacing;
    }
  }
}
