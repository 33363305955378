@import "../../variables";

.Header {
  background: $white;
  border-bottom: $border-width solid $border-color;

  #menu-toggle {
    font-size: 2rem;
    height: 70px;
    width: 70px;
    border-radius: 0;
    border: none;
  }

  .input-group {
    .input-group-text {
      background: transparent;
      border: 0;
    }
  }

  .form-control {
    border: none;
  }
}

#search-input-group input {
  background-color: $body-bg;
}

#search-icon {
  background-color: $card-header;
  > span {
    margin-top: 0.25rem;
  }

  .fa-search {
    color: white;
  }
}
