@import "../../variables";

.button-section {
  position: absolute;
  top: 1rem;
  right: 1rem;

  .btn {
    margin-left: 0.5 * $spacer;

    &:first-child {
      margin-left: 0;
    }
  }
}
