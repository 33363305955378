@import "../../../node_modules/react-day-picker/lib/style.css";

.TemperatureFilters {
  .btn {
    color: white;
  }

  .dropdown-item {
    &.withDayPicker:hover {
      background: inherit;
      color: inherit;
    }
  }

  .show {
    > button.dropdown-toggle {
      color: white;
    }
  }
}
