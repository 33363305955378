@import "../../variables.scss";

form {
  .form-group {
    .row {
      .rule-header {
        white-space: pre;
      }

      .rule-field {
        padding-right: 0;
      }

      .help-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;

        :hover {
          cursor: pointer;
        }
      }
    }
  }
}
