@import "../../variables.scss";

.rule-field {
  .boundary {
    display: flex;
    position: relative;
  }
  .invalid-feedback {
    display: block;
  }
}


