@import "../variables.scss";

#Loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $body-bg;
}
